import React from 'react'
import styled from 'styled-components'
import { color, breakpoint, transition } from '@configs/utilities'
import { HeadingH1, DescriptionDisplay1 } from '@components/ui/typography/Typography'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import { useIntl } from 'gatsby-plugin-react-intl'
import { generateAppLink } from '@configs/helpers'

const FeatureHeroSection = (props) => {
  const intl = useIntl()

  return (
    <SectionWrapper sectionBackground={props.feature.heroBackgroundColor} showWaves>
      <FeatureHeroWrapper>
        <div className={'iconFeaturesTabWrapper'}>
          <IconTextWrapper>
            <img src={props.feature.tabIcon.publicURL} alt={props.feature.slug} width={38} height={30} loading="lazy" />
            <span className={`activeIconText-${props.feature.slug}`}>
              {props.intl.formatMessage({ id: props.feature.tabName })}
            </span>
          </IconTextWrapper>
          <FeaturesTabContentTextWrapper>
            <HeadingWrapper>
              <HeadingH1>{props.intl.formatMessage({ id: props.feature.title })}</HeadingH1>
            </HeadingWrapper>
            <DescriptionWrapper>
              <DescriptionDisplay1>{props.intl.formatMessage({ id: props.feature.text })}</DescriptionDisplay1>
            </DescriptionWrapper>
            <ButtonWrapper>
              <ButtonLink
                external={props.feature.heroButtonExternal}
                to={props.feature.heroButtonPath}
                href={generateAppLink('signup', intl.locale)}
                buttonText={props.intl.formatMessage({ id: props.feature.heroButtonText })}
                explain={props.feature.heroButtonExplain}
                explainText={props.intl.formatMessage({ id: props.feature.heroButtonExplainText })}
                mdfontsize={'20px'}
                align={'flex-start'}
              />
            </ButtonWrapper>
          </FeaturesTabContentTextWrapper>
        </div>
        <FeaturesTabContentVideoWrapper>
          <VideoWrapper>
            <video className="active" width="550px" muted={true} playsInline loop={true} autoPlay={true}>
              <source src={props.feature.video.mp4.publicURL} type="video/mp4" />
              <source src={props.feature.video.ogv.publicURL} type="video/ogv" />
              <source src={props.feature.video.webm.publicURL} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </VideoWrapper>
        </FeaturesTabContentVideoWrapper>
      </FeatureHeroWrapper>
    </SectionWrapper>
  )
}

export default FeatureHeroSection

const FeatureHeroWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  margin-bottom: 7%;

  @media screen and (min-width: ${breakpoint.lg}) {
    align-items: flex-start;
    flex-direction: row;
    column-gap: 50px;
    justify-content: space-between;
  }

  .iconFeaturesTabWrapper {
    @media screen and (min-width: ${breakpoint.lg}) {
      width: 40%;
    }
  }
`

/* --------------------------------------------------------  TAB ICON/TEXT TITLE  -------------------------------------------------------- */
const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;

  @media screen and (min-width: ${breakpoint.lg}) {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  img {
    height: 18px;
    margin-right: 2px;
  }

  .activeIconText-ai-assistance {
    color: ${color.ai};
  }

  .activeIconText-idea-plan {
    color: ${color.ideaPlan};
  }

  .activeIconText-business-guide {
    color: ${color.businessGuide};
  }

  .activeIconText-financial-plan {
    color: ${color.financialPlan};
  }

  .activeIconText-idea-validation {
    color: ${color.ideaValidation};
  }
  .activeIconText-business-plan {
    color: ${color.businessPlan};
  }
  .activeIconText-whiteboard {
    color: ${color.whiteboard};
  }
`

/* --------------------------------------------------------  TAB CONTENT  -------------------------------------------------------- */
const FeaturesTabContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${color.font};
  max-width: 600px;

  @media screen and (min-width: ${breakpoint.lg}) {
    align-items: flex-start;
    text-align: left;
  }
`

const HeadingWrapper = styled.div`
  margin-bottom: 10px;

  @media screen and (min-width: ${breakpoint.md}) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    margin-bottom: 20px;
  }
`
const DescriptionWrapper = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: ${breakpoint.md}) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    margin-bottom: 40px;
  }
`
const ButtonWrapper = styled.div``

/* --------------------------------------------------------  TAB VIDEO  -------------------------------------------------------- */
const FeaturesTabContentVideoWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.lg}) {
    width: 50%;
  }
`

export const VideoWrapper = styled.div`
  video {
    width: 100%;
    background-color: white;
    border-radius: 13px;
    border: 6px solid #081e4a;
    transition: ${transition.default};
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import { color } from '@configs/utilities'
import RatingSection from '@sections/home/RatingSection'
import BenefitsSection from '@sections/home/BenefitsSection'
import AdvertisementSection from '@sections/common/AdvertisementSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import FeatureHeroSection from '@sections/features/FeatureHeroSection'
import ExplainSection from '@sections/features/ExplainSection'
import CustomizableTemplatesSection from '@sections/features/CustomizableTemplatesSection'
import { mergeTemplateIds } from '@configs/templates'
import ReadySection from '@sections/common/ReadySection'
import AiAssistanceFAQSection from '@sections/features/AiAssistanceFAQSection'
import { generateAppLink } from '@configs/helpers'
import AiAssistanceSection from '@sections/home/AIAssistanceSection'
import WhiteboardSection from '@sections/features/WhiteboardSection'
export const query = graphql`
  query ($slug: String!) {
    featuresJson(slug: { eq: $slug }) {
      slug
      tabIcon {
        publicURL
      }
      tabName
      heroBackgroundColor
      title
      text
      heroButtonText
      heroButtonExternal
      heroButtonPath
      heroButtonExplain
      heroButtonExplainText
      video {
        mp4 {
          publicURL
        }
        ogv {
          publicURL
        }
        webm {
          publicURL
        }
      }
      explainTitle
      explainDescription
      explain {
        reverse
        label
        text
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [320, 768, 1380]
            )
          }
        }
      }
      aiSection
      customizableTemplatesCards {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              breakpoints: [320, 768, 1380]
            )
          }
        }
        icon {
          publicURL
        }
        name
        iconText
        template
        linkText
      }
      advertisementSection {
        title
        subTitle
        buttonExternal
        buttonPath
        buttonText
      }
      readyToStartSection {
        title
        subTitle
        buttonExternal
        buttonPath
        buttonText
        buttonExplain
        buttonExplainText
      }
    }
  }
`

const Features = ({ data, intl }) => {
  const feature = data.featuresJson
  const explain = data.featuresJson.explain
  const customizableTemplatesCards = mergeTemplateIds(data.featuresJson.customizableTemplatesCards)
  const showTemplateSection =
    !['idea-validation', 'whiteboard'].includes(feature.slug) && customizableTemplatesCards.length
  const showWhiteboardSection = ['whiteboard'].includes(feature.slug) && customizableTemplatesCards.length

  return (
    <Layout pageName={`features-${feature.slug}`}>
      <FeatureHeroSection feature={feature} intl={intl} />
      <RatingSection
        sectionPaddingSm="10px 0 10px 0"
        sectionPaddingMd="10px 0 10px 0"
        sectionPaddingXl="10px 0 10px 0"
      />
      <ExplainSection feature={feature} explain={explain} intl={intl} />
      {feature.aiSection && <AiAssistanceSection />}
      {showTemplateSection && (
        <CustomizableTemplatesSection
          handwriting={intl.formatMessage({ id: 'feature.customizableTemplates.handwriting' })}
          title={intl.formatMessage({ id: 'feature.customizableTemplates.title' })}
          description={intl.formatMessage({ id: 'feature.customizableTemplates.description' })}
          customizableTemplatesCards={customizableTemplatesCards}
          feature={feature}
        />
      )}
      {showWhiteboardSection && (
        <WhiteboardSection
          handwriting={intl.formatMessage({ id: 'feature.customizableTemplates.handwriting' })}
          title={intl.formatMessage({ id: 'feature.customizableTemplates.title' })}
          description={intl.formatMessage({ id: 'feature.customizableTemplates.description' })}
        />
      )}
      <AdvertisementSection
        componentBackground={color.blueDark}
        titleText={intl.formatMessage({ id: feature.advertisementSection.title })}
        subtitleText={intl.formatMessage({ id: feature.advertisementSection.subTitle })}
        external={feature.advertisementSection.buttonExternal}
        to={feature.advertisementSection.buttonPath}
        href={generateAppLink('signup', intl.locale)}
        buttonBackground={color.yellow}
        buttonColor={color.white}
        buttonText={intl.formatMessage({ id: feature.advertisementSection.buttonText })}
      />
      <BenefitsSection />
      <TestimonialsSection />
      {feature.slug === 'ai-assistance' && <AiAssistanceFAQSection />}
      <ReadySection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: feature.readyToStartSection.title })}
        description={intl.formatMessage({ id: feature.readyToStartSection.subTitle })}
        buttonText={intl.formatMessage({ id: feature.readyToStartSection.buttonText })}
        external={feature.readyToStartSection.buttonExternal}
        to={feature.readyToStartSection.buttonPath}
        href={generateAppLink('signup', intl.locale)}
        explain={feature.readyToStartSection.buttonExplain}
        explainText={intl.formatMessage({ id: feature.readyToStartSection.buttonExplainText })}
      />
    </Layout>
  )
}

export default injectIntl(Features)

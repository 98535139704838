import React from 'react'
import styled from 'styled-components'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import { breakpoint } from '@configs/utilities'
import CustomizableTemplatesCard from '@components/pages/features/CustomizableTemplatesCard'
import { graphql, useStaticQuery } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
const WhiteboardSection = ({ intl, handwriting, title, description }) => {
  const data = useStaticQuery(graphql`
    query {
      allWhiteboardCardsJson {
        edges {
          node {
            slug
            text
            image {
              childImageSharp {
                gatsbyImageData(width: 212, placeholder: BLURRED, formats: [AUTO, WEBP], breakpoints: [320, 768, 1380])
              }
            }
          }
        }
      }
    }
  `)

  return (
    <SectionWrapper>
      <SectionHeader
        handwriting={handwriting}
        title={title}
        description={description}
        titleMaxWidth="600px"
        descriptionMaxWidth="600px"
      />
      <CardsWrapper>
        {data.allWhiteboardCardsJson.edges.map((item, index) => {
          return (
            <div key={index}>
              <CustomizableTemplatesCard
                image={item.node.image.childImageSharp.gatsbyImageData}
                iconText={intl.formatMessage({ id: item.node.text })}
              />
            </div>
          )
        })}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(WhiteboardSection)

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  div {
    max-width: 280px;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 222px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      max-width: 212px;
    }
  }
`

import React from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionHeader from '@components/ui/typography/SectionHeader'
import CustomizableTemplatesCard from '@components/pages/features/CustomizableTemplatesCard'

const CustomizableTemplatesSection = (props) => {
  return (
    <SectionWrapper>
      <SectionHeader
        handwriting={props.handwriting}
        title={props.title}
        description={props.description}
        titleMaxWidth="600px"
        descriptionMaxWidth="600px"
      />
      <CardsWrapper>
        {props.customizableTemplatesCards.map((item, index) => {
          return (
            <div key={index}>
              <CustomizableTemplatesCard
                feature={item.feature}
                image={item.image.childImageSharp.gatsbyImageData}
                icon={item.icon.publicURL}
                iconText={props.intl.formatMessage({ id: item.iconText })}
                // template={props.intl.formatMessage({ id: item.template })}
                external={item.external}
                path={item.path}
                pathKey={item.pathKey}
                targetBlank={item.targetBlank}
                linkText={props.intl.formatMessage({ id: item.linkText })}
                color={props.feature.slug}
              />
            </div>
          )
        })}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(CustomizableTemplatesSection)

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  div {
    max-width: 280px;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 222px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      max-width: 212px;
    }
  }
`
